import { animated, useSpring } from "react-spring";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import assetOne from "../assets/mockup.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

const AppDevStructure = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.1,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
    >
      <div
        id="howwehelpartists"
        style={{
          position: "relative",
          scrollMarginTop: 100,
          minWidth: "100vw",
          padding: "100px 40px",
        }}
        className="container"
      >
        <center>
          <div className="col">
            <animated.h1 style={titleAnimation}>
              <span className="main-heading">
                App Development and Structure
              </span>
            </animated.h1>
            <br /> <br />
            <span className="main-heading" style={{ fontSize: 32 }}>
              How Coda Makes Money
            </span>
            <p className="main-p">
              <br />
              The company makes money through a $9.99/mo and 29.99/mo premium subscription
              that allows access to special groups and exclusive NFTs. A small transaction fee is
              taken whenever payments are made within the platform. Artists and listeners will be
              paid out in a central ERC-20 token that the platform will run on.
              <br />
            </p>
            <br />
            <span className="main-heading" style={{ fontSize: 32 }}>
              Site Layout
            </span>
            <br /> <br />
            <div className="row justify-content-center">
              <div className="col-md-auto help-card">
                <p className="help-card-description">
                  A streaming and music discovery section.
                </p>
              </div>
              <div className="col-md-auto help-card">
                <p className="help-card-description">
                  A social feed where artists will keep fans up to date on their
                  latest music and newly minted NFT drops/auctions.
                </p>
              </div>
              <div className="col-md-auto help-card">
                <p className="help-card-description">
                  An NFT marketplace where users can search through to see which artists are
                  offering what and buy and sell music royalty rights, along with many other types
                  of NFTs

                </p>
              </div>
            </div>
            <br /> <br />
            <span className="main-heading" style={{ fontSize: 32 }}>
              Site Features
            </span>
            <br />
            <img
              src={assetOne}
              style={{ width: "60%", pointerEvents: "none" }}
            />
            <p className="main-p">
              <br />
              Artists are able to make their own social groups that they run from inside the platform,
              perfect for cultivating an engaged community. These groups are exclusive to whoever
              the artists choose. It could be their most engaged listeners, or maybe only their first 100
              followers. They can also set a price for a monthly subscription fee, or plenty of other
              creative options. Artists can release exclusive NFTs, unreleased songs, and keep
              subscribers up to date within these groups. Artists can choose whether they want their
              group to be opted into the premium version of the platform or remain exclusive to the
              parameters they set. Small artists who value the exposure over the revenue will most
              likely opt in, while larger artists are likely to remain exclusive. The premium subscription
              allows the user access to all artist circles who opted to put their group as a part of the
              premium version.

              <br />
              <br />
              Our user base is broken down into three tiers based on how much engagement each
              person is providing artists and the platform. There is a point system to keep track that
              unlocks special features as the user engages with the platform. Users earn different
              amounts of points for different actions, such as sharing the platform or music with
              friends, buying NFTs, donating to artists, listening to music, owning music rights, and
              many more. These points are then used to unlock things within the platform.
              <br />
            </p>
          </div>
        </center>
      </div>
    </motion.div>
  );
};

export default AppDevStructure;
