import { animated, useSpring } from "react-spring";

const EmailForm = () => {
  const titleAnimation = useSpring({
    from: {
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  return (
    <center>
      <animated.h1 style={titleAnimation}>
        <span className="main-heading">Subscribe for Launch</span>
      </animated.h1>
      <br />
      <p className="main-p">Enter your email to subcribe for launch.</p>
      <iframe
        id="subscribe"
        width="100%"
        height="400"
        src="https://489199ad.sibforms.com/serve/MUIEAL7o3Kkl-fYcCiZxItZJvVocxuMPs069X34MBSiEJp9EseX2_PZuScWjeJUgNSwT9_evEsJrobw4-sVQGyEv-plHUh9n207CknirttWrxwQbmJq_6GKI5DbpqVzuO-Te0aXunikbFY_r-iaEWPjY9bhPVP_Q102FV9b1MzZU4MaCf6T8Ny85Z_G8SKEYDpIu6Bn9FOf2EzfH"
        frameborder="0"
        scrolling="auto"
        allowfullscreen
      ></iframe>
    </center>
  );
};

export default EmailForm;
