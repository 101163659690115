import { animated, useSpring } from "react-spring";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import useWindowDimensions from "../hooks/useWindowDimensions";

const HowWeHelpArtists = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.1,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
    >
      <div
        id="howwehelpartists"
        style={{
          position: "relative",
          scrollMarginTop: 50,
          minWidth: "100vw",
          padding: "100px 40px",
        }}
        className="container"
      >
        <center>
          <div className="col">
            <animated.h1 style={titleAnimation}>
              <span className="main-heading">How We Are Helping Artists</span>
            </animated.h1>

            <br />
            <p className="main-p">
              <br />
              Coda resides on the Ethereum blockchain which allows users to collect instant
              payments, mint royalty sharing NFTs, collectable NFTs, NFTs with exclusive listening
              rights and many other features, giving the artist a lot more options for streams of income
              as well as creativity over distribution.
              <br />
              <br />
              We are giving our artists ultimate creative freedom as to whom they make their songs
              and NFTs available. We give them the metrics to measure engagement and provide a
              feedback loop to help nurture a connection between artists and their listeners. This way
              artists can choose to gift their biggest fans and promoters special opportunities and
              exclusive NFTs, encouraging listeners to further support their favorite artists.
              <br />
              <br />
              The sale of music rights and NFTs are important to create a shared interest between the
              artist and the listener, the better the artist does, the more the listener will benefit as well.
              The more the listeners do to help an artist grow their audience, the more they will both
              benefit.
              <br />
            </p>
          </div>
        </center>
      </div>
    </motion.div>
  );
};

export default HowWeHelpArtists;
