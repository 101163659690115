import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const RoadmapCard = ({ title, desc, dir, img }) => {
  const viewAnim = {
    visible: {
      opacity: 1,
      x: 0,
      rotate: 0,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.5,
      scale: 0.9,
      x: dir === "r" ? 50 : -50,
      rotate: 3,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.3 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.li
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
    >
      <div className={`roadmap-dir-${dir}`}>
        <img src={img} className="roadmap-img" />
        <div className="title-wrapper">
          <span className="roadmap-title">{title}</span>
        </div>
        <motion.div
          className="roadmap-desc"
          whileHover={{
            background:
              "linear-gradient(to right bottom, var(--primary), var(--secondary))",
            transition: { ease: "easeOut", duration: 2 },
          }}
        >
          {desc}
        </motion.div>
      </div>
    </motion.li>
  );
};

export default RoadmapCard;
