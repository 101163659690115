import { animated, useSpring } from "react-spring";
import FAQCard from "../Components/FAQCard";

const Utility = () => {
  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      marginBottom: 40,
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        marginBottom: 40,
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  return (
    <div className="container" id="utility" style={{ scrollMarginTop: 140 }}>
      <div style={{ marginTop: 100, marginBottom: 140 }}>
        <center>
          <animated.h1 style={titleAnimation} className="heading-h1">
            <span className="main-heading">
              What utility/financial benefits does buying a Coda Genesis NFT
              Provide?
            </span>
          </animated.h1>
          <br />
          <p className="main-p">
            <strong>Plenty, utility will include;</strong>
          </p>

          <div className="row" style={{ marginTop: 40 }}>
            <div className="FAQCard">
              <FAQCard
                title="Exclusive invite to be first to join the platform."
                text="Coda Genesis NFT holders will then be the first ones invited to the platform after
                reviewing small group user feedback and bug fixes."
              />
              <FAQCard
                title="Highest referral/revenue sharing percentage available from songs streamed
                through your playlist."
                text="Our user base will be broken down into tiers based on how much engagement
                listeners and artists are providing other artists. These tiers come with differing
                levels of benefits, one of which is higher revenue share percentage.<br/><br/>Active AMP listeners who share their playlists and grow their followers are
                entitled to a referral fee. They will receive a percentage of each listen streamed
                through their playlist. The higher each AMP’s engagement the more they earn.
                This encourages people to share their playlists and promote the artists on them.<br/><br/>
                Holding Coda Genesis NFT will jump start you off at the highest tier of revenue sharing available right out of the gate."
              />
              <FAQCard
                title="Large portion of our ERC-20 Token."
                text="We will lock a percentage of the revenue generated from the launch to build the
                ERC-20 token that the platform will run off of, Coda Genesis NFT holders will have a large percentage of these coins distributed amongst them as a thank you
                for being the first to join this community"
              />
              <FAQCard
                title="Bonus engagement points."
                text="Large amounts of additional engagement points will be added to holders
                accounts when the first platform invites go out in 3-5 months. These points are
                used to unlock exclusive features and benefits within the platform."
              />
              <FAQCard
                title="Free access to 100 artists exclusive groups of your choice"
                text="Coda Genesis NFT holders will also receive access to 100 artist exclusive circles
                who opted into our premium model, giving you the opportunity to invest in highly
                lucrative NFTs, normally only available by subscription to the artist’s circle and
                set criteria. As stated before, we give full creative ability to the artists when it
                comes to whom they make their NFTs available."
              />
              <FAQCard
                title="A special place for Genesis holders in our discord"
                text="This will be used to communicate with other holders and give the opportunity to
                have real input on how the site is built and features you may want added. It also
                gives access to any giveaways we may run in the future, exclusive NFT sales or
                other rewards for being our first users within that discord channel."
              />
              <FAQCard
                title="Access to future airdrops"
                text="These future airdrops will provide further utility once the platform goes live and
                will be gifted as a continuous thank you to our first users."
              />
              <FAQCard
                title="Profile Verification that proves you were among our first 10,000 users."
                text="Everyone who holds a Coda Genesis NFT will have an exclusive verification on
                their profile that proves they were one of the few early adopters to join the music
                revolution on our platform."
              />
              <FAQCard
                title="3 Keys to ANY artist's exclusive group despite criteria to get in."
                text="Three Master Keys: Each Coda Genesis NFT holder will receive three 3 Master
                Keys that are one time use only, but give the holder the ability to subscribe you to
                ANY exclusive artist group. This includes bigger artists with more popular NFT
                collections and higher prices, who may have more strict standards as to who they
                let into their exclusive circle."
              />
              <FAQCard
                title="Any additional resale value"
                text="If you are able to purchase a Coda Genesis NFT we will be sending out an NFT
                containing all the utilities promised to Genesis holders in the form of an airdrop. This will
                take place 3-4 weeks after the initial sale date, this gives our team time to continue to
                work on completing the smart contracts required to make this utility possible."
              />
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};

export default Utility;
