import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "react-spring";

import promo_1 from "../assets/promotional_1.png";
import promo_2 from "../assets/promotional_2.png";
import promo_3 from "../assets/promotional_3.png";
import promo_4 from "../assets/promotional_4.png";
import promo_5 from "../assets/promotional_5.png";
import promo_6 from "../assets/promotional_6.png";
import promo_7 from "../assets/promotional_7.png";
import promo_8 from "../assets/promotional_8.png";

import useWindowDimensions from "../hooks/useWindowDimensions";

const Promotional = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      marginBottom: 40,
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        marginBottom: 40,
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.1,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      id="promotional"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
      style={{
        scrollMarginTop: 140,
        minHeight: "60vh",
      }}
    >
      <div style={{ marginBottom: 100 }}>
        <center>
          <animated.h1 style={titleAnimation}>
            <div>
              <span className="main-heading">Our App</span>
            </div>
          </animated.h1>
        </center>
      </div>
      <div className="row justify-content-center">
        <div
          id="app-carousel"
          class="carousel slide"
          style={{ width: "75%" }}
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100 promotional-card"
                src={promo_1}
                alt="promo1"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 promotional-card"
                src={promo_2}
                alt="promo1"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 promotional-card"
                src={promo_3}
                alt="promo3"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 promotional-card"
                src={promo_4}
                alt="promo4"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 promotional-card"
                src={promo_5}
                alt="promo5"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 promotional-card"
                src={promo_6}
                alt="promo6"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 promotional-card"
                src={promo_7}
                alt="promo7"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 promotional-card"
                src={promo_8}
                alt="promo8"
              />
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#app-carousel"
            role="button"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#app-carousel"
            role="button"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
      {/* <div className="col-md-auto promotional-card">
          <img src={promo_1} alt="promo_1" />
        </div>
        <div className="col-md-auto promotional-card">
          <img src={promo_2} alt="promo_2" />
        </div>
        <div className="col-md-auto promotional-card">
          <img src={promo_3} alt="promo_3" />
        </div> */}
    </motion.div>
  );
};

export default Promotional;
