import { motion } from "framer-motion";
import { animated, useSpring } from "react-spring";

import logo from "../assets/logo.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Navigation = () => {
  const { width } = useWindowDimensions();
  const navAnimation = useSpring({
    from: {
      opacity: 0,
      scale: 0.5,
      transform: "translateY(-50px) rotate(10deg)",
    },
    to: [{ opacity: 1, scale: 1, transform: "translateY(0px) rotate(0deg)" }],
    config: { mass: 2, tension: 190, friction: 22 },
  });

  return (
    <nav
      className={`navbar navbar-dark fixed-top ${
        width > 992 && "px-5"
      } pt-3 navbar-expand-lg justify-content-center`}
    >
      <animated.a
        href="/"
        className={`navbar-brand d-flex ${width < 992 && "mx-4"} w-50 mr-auto`}
        style={navAnimation}
      >
        <motion.img
          src={logo}
          alt="img"
          style={{ width: width > 992 ? "30%" : "80%" }}
        />
      </animated.a>

      <button
        className={`btn navbar-toggler ${width < 992 && "mx-3"}  shadow-none`}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar"
      >
        <span style={{ fontSize: 24 }} className="navbar-toggler-icon"></span>
      </button>
      <div
        className="navbar-collapse collapse w-100 justify-content-center"
        id="navbar"
      >
        <ul className="navbar-nav mt-4 w-50 justify-content-center">
          <animated.li className="nav-item" style={navAnimation}>
            <motion.a className="nav-link" href="#whatwedo">
              <span>What We Do</span>
            </motion.a>
          </animated.li>
          <animated.li className="nav-item" style={navAnimation}>
            <motion.a className="nav-link" href="#promotional">
              <span>Our App</span>
            </motion.a>
          </animated.li>
          <animated.li className="nav-item" style={navAnimation}>
            <motion.a className="nav-link" href="#howwehelpartists">
              <span>How We Help</span>
            </motion.a>
          </animated.li>
          <animated.li className="nav-item" style={navAnimation}>
            <motion.a className="nav-link" href="#solutions">
              <span>Solutions</span>
            </motion.a>
          </animated.li>
          <animated.li className="nav-item" style={navAnimation}>
            <motion.a className="nav-link" href="#roadmap">
              <span>Roadmap</span>
            </motion.a>
          </animated.li>
          <animated.li className="nav-item" style={navAnimation}>
            <motion.a className="nav-link" href="#faq">
              <span>FAQ</span>
            </motion.a>
          </animated.li>
        </ul>
        {width > 1500 && (
          <ul className="nav navbar-nav ml-auto w-100 justify-content-end">
            <animated.li className="nav-item" style={navAnimation}>
              <a href="">
                <button
                  className="main-btn"
                  style={{
                    maxWidth: 200,
                    padding: "10px",
                    textAlign: "center",
                    fontSize: 14,
                  }}
                >
                  Mint Coming Soon!
                </button>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a href="#subscribe" style={{ all: "unset" }}>
                <button
                  className="main-btn"
                  style={{
                    padding: width < 1900 ? "10px 14px 10px 5px" : "10px",
                    margin: "0 12px 0px 12px",
                    fontSize: 14,
                  }}
                >
                  {width > 1900 && "Signup for Launch"}
                  {width < 1900 && <i class="bi bi-info-circle-fill"></i>}
                </button>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a
                style={{
                  all: "unset",
                }}
                target="blank"
                rel="noreferrer"
                href="https:discord.gg/sPMsdhznna"
              >
                <button
                  className="main-btn"
                  style={{
                    padding: "10px 14px 10px 5px",
                  }}
                >
                  <i className="fab fa-discord"></i>
                </button>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a
                style={{
                  all: "unset",
                }}
                target="blank"
                rel="noreferrer"
                href="https://twitter.com/Codastreaming"
              >
                <button
                  className="main-btn"
                  style={{
                    padding: "10px 14px 10px 5px",
                    margin: "0 12px 0px 12px",
                  }}
                >
                  <i className="fab fa-twitter" />
                </button>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a
                style={{
                  all: "unset",
                }}
                target="blank"
                rel="noreferrer"
                href="https://www.instagram.com/codastreaming"
              >
                <button
                  className="main-btn"
                  style={{
                    padding: "10px 14px 10px 5px",
                  }}
                >
                  <i className="fab fa-instagram" />
                </button>
              </a>
            </animated.li>
          </ul>
        )}

        {width > 1024 && width <= 1500 && (
          <ul className="nav navbar-nav ml-auto w-100 justify-content-end hstack">
            <animated.li className="nav-item" style={navAnimation}>
              <a href="">
                <button
                  className="main-btn"
                  style={{
                    maxWidth: 200,
                    maxHeight: 30,
                    padding: "6px 10px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  Mint Coming Soon!
                </button>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a href="#subscribe" style={{ all: "unset" }}>
                <button
                  className="main-btn"
                  style={{
                    padding: width < 1900 ? "10px 14px 10px 5px" : "10px",
                    margin: "0 12px 0px 12px",
                    maxWidth: 30,
                    maxHeight: 30,
                  }}
                >
                  {width > 1900 && "Signup for Launch"}
                  {width < 1900 && (
                    <div
                      className="col"
                      style={{ marginTop: "-8px", marginLeft: "-5px" }}
                    >
                      <i
                        class="bi bi-info-circle-fill"
                        style={{ fontSize: "12px" }}
                      ></i>
                    </div>
                  )}
                </button>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a
                style={{
                  all: "unset",
                }}
                target="blank"
                rel="noreferrer"
                href="https://discord.gg/TXEvZ5GdDf"
              >
                <button
                  className="main-btn"
                  style={{
                    padding: "10px 14px 10px 5px",
                    maxWidth: 30,
                    maxHeight: 30,
                  }}
                >
                  <div
                    className="col"
                    style={{ marginTop: "-8px", marginLeft: "-7px" }}
                  >
                    <i class="fab fa-discord" style={{ fontSize: "12px" }}></i>
                  </div>
                </button>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a
                style={{
                  all: "unset",
                }}
                target="blank"
                rel="noreferrer"
                href="https://twitter.com/Codastreaming"
              >
                <button
                  className="main-btn"
                  style={{
                    padding: "10px 14px 10px 5px",
                    margin: "0 12px 0px 12px",
                    maxWidth: 30,
                    maxHeight: 30,
                  }}
                >
                  {" "}
                  <div
                    className="col"
                    style={{ marginTop: "-8px", marginLeft: "-5px" }}
                  >
                    <i class="fab fa-twitter" style={{ fontSize: "12px" }}></i>
                  </div>
                </button>
              </a>
            </animated.li>
            <animated.li className="nav-item" style={navAnimation}>
              <a
                style={{
                  all: "unset",
                }}
                target="blank"
                rel="noreferrer"
                href="https://www.instagram.com/codastreaming"
              >
                <button
                  className="main-btn"
                  style={{
                    padding: "10px 14px 10px 5px",
                    maxWidth: 30,
                    maxHeight: 30,
                  }}
                >
                  <div
                    className="col"
                    style={{ marginTop: "-8px", marginLeft: "-5px" }}
                  >
                    <i
                      class="fab fa-instagram"
                      style={{ fontSize: "12px" }}
                    ></i>
                  </div>
                </button>
              </a>
            </animated.li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
