import { animated, useSpring } from "react-spring";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import useWindowDimensions from "../hooks/useWindowDimensions";

const WhyWeDo = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.1,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
    >
      <div
        id="whywedo"
        style={{
          position: "relative",
          minWidth: "100vw",
          padding: "100px 40px",
          scrollMarginTop: 100,
          marginBottom: width < 900 && 40,
        }}
        className="container"
      >
        <center>
          <div className="col">
            <div className="col">
              <animated.h1 style={titleAnimation}>
                <span className="main-heading">Why Are We Doing This?</span>
              </animated.h1>

              <br />
              <p className="main-p">
                <br />
                There has been a long-standing battle in the music industry – who owns the rights to
                music, the artists or the label/studio?
                <br />
                <br />
                People have tried to solve this problem over the years with little success. With the onset
                of Blockchain, Coda Streaming has the opportunity to solve this problem by giving
                artists more freedom over distribution and fundraising.

                <br />
                <br />
                Every year extremely talented artists give up on their dream because the industry deck
                is stacked against them. Trying to raise money to buy expensive studio time to cut their
                tracks with professional production; once produced, getting those tracks played on radio
                stations; and the most challenging, trying to grow a loyal following so they can make a
                living off their creative gifts. With this, we coin the term, “Starving Artist.”
                <br />
                <br />
                Coda hopes to bring a little more creativity into a world that makes it inherently hard for
                these creatives and nonconformers to succeed
                <br />
                <br />
                This is the mission that we have set out to accomplish and it is a problem worth
                devoting a lifetime to solving. The amount of care that our founding team has for making
                it that much easier for artists to bring a little magic into the world with their music is not
                matched by any other company. It is because of this care that we will succeed. We
                know how many people this platform will be able to help and how many lives it will
                change at its maximum reach. It would be an injustice to those people to let this
                opportunity slip by.

                <br />
              </p>
            </div>
          </div>
        </center>
      </div>
    </motion.div>
  );
};

export default WhyWeDo;
