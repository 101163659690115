import { animated, useSpring } from "react-spring";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import assetOne from "../assets/nft_anim.gif";
import useWindowDimensions from "../hooks/useWindowDimensions";

const WhatWeDo = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.1,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
    >
      <div
        id="whatwedo"
        style={{
          position: "relative",
          minWidth: "100vw",
          padding: "100px 40px",
          scrollMarginTop: 60,
          marginBottom: width < 900 && 40,
        }}
        className="container"
      >
        <center>
          <div className="col">
            <div
              className="row justify-content-center"
              style={{ marginRight: width > 1200 && -200 }}
            >
              <div
                className="col"
                style={{ marginRight: width > 1200 && -200 }}
              >
                <animated.h1 style={titleAnimation}>
                  <span className="main-heading">What We Do</span>
                </animated.h1>
                {width < 1200 && <br />}
                {width < 1200 && (
                  <img
                    src={assetOne}
                    width={width < 1200 ? "60%" : width < 992 && "90%"}
                    style={{ pointerEvents: "none" }}
                  />
                )}
                <p
                  className="main-p"
                  style={{
                    textAlign: width > 1200 && "right",
                    width: "80%",
                  }}
                >
                  <br />
                  Coda helps artists grow by allowing our listeners to invest in
                  the artists they love and profit alongside them! To accomplish
                  this we are launching our first NFT collection to fund future
                  operations.
                  <br />
                  <br />
                  The goal of Coda is to provide a streaming and social platform
                  that is focused on helping artists build an engaged fan base
                  that is easily monetizable through an in-app NFT and royalty
                  sharing marketplace
                  <br />
                  <br />
                  Artists will be able to mint NFT collectables and royalty
                  sharing NFTs where they can allow listeners to invest in their
                  songs to get funding, instead of going to a label that takes
                  80% of the value the artist creates. Once a listener is
                  invested with an artist, they help promote that artist’s
                  growth. This way the artists will keep the 80% and 20% can go
                  to their army of Coda Promoters!
                  <br />
                  <br />
                </p>
                <a
                  style={{ all: "unset" }}
                  href="https://discord.gg/TXEvZ5GdDf"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="main-btn">
                    <span>Join our Discord</span>
                    <i className="fab fa-discord"></i>
                  </button>
                </a>
              </div>
              {width > 1200 && (
                <div className="col" style={{ margin: "auto 100px auto 0px" }}>
                  <img src={assetOne} style={{ pointerEvents: "none" }} />
                </div>
              )}
            </div>
          </div>
        </center>
      </div>
    </motion.div>
  );
};

export default WhatWeDo;
