import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { animated, useSpring } from "react-spring";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { connect } from "../redux/blockchain/blockchainActions";
import { useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";

const MintConnect = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const blockchain = useSelector((state) => state.blockchain);

  const [claimingNFT, setNFTClaimed] = useState(false);
  const [buyQuantity, setBuyQuantity] = useState(1);
  const [isConnected, setConnected] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [connectBtnText, setConnectBtnText] = useState("Connect");
  const [displayMsg, setDisplayMsg] = useState("Click buy to mint your NFT.");

  const toastMessage = (message) => toast(message);

  const [loadedConfig, setLoadedConfig] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    MAX_BUY_QUANTITY: 10,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
  });

  useEffect(() => {
    (async function () {
      await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          const config = await response.json();
          setLoadedConfig(config);
        })
        .catch((e) => {
          console.log(e);
        });
    })();
  }, []);

  const handleBuyQuantityDecrement = () => {
    buyQuantity > 1 && setBuyQuantity(buyQuantity - 1);
  };

  const handleBuyQuantityIncrement = () => {
    buyQuantity < loadedConfig.MAX_BUY_QUANTITY &&
      setBuyQuantity(buyQuantity + 1);
  };

  const handleConnect = (e) => {
    e.preventDefault();
    if (blockchain.account === null) {
      setLoading(true);
      !isConnected && setConnectBtnText("Connecting...");
      dispatch(connect()).then((response) => {
        if (response !== undefined) {
          setConnected(false);
          setLoading(false);
          setConnectBtnText("Connect");
          toastMessage(response.payload);
        }
      });
      getData();
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setConnected(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    displayMsg !== "Click buy to mint your NFT." && toastMessage(displayMsg);
  }, [displayMsg]);

  const handleBuyNFT = (e) => {
    e.preventDefault();
    claimNFTs();
    setLoading(true);
    getData();
  };

  const claimNFTs = () => {
    let cost = loadedConfig.WEI_COST;
    let gasLimit = loadedConfig.GAS_LIMIT;
    let totalCostWei = String(cost * buyQuantity);
    let totalGasLimit = String(gasLimit * buyQuantity);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setDisplayMsg(`Minting your ${loadedConfig.NFT_NAME}...`);
    setNFTClaimed(true);
    console.log(loadedConfig.CONTRACT_ADDRESS);
    console.log(blockchain.account);
    console.log(totalCostWei);
    var gasAmount = blockchain.smartContract.methods
      .mint(buyQuantity)
      .estimateGas({
        to: loadedConfig.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .then((estimatedGas) => {
        console.log(gasAmount);
        estimatedGas = Math.round(estimatedGas * 1.2);
        console.log("gas limit estimation = " + estimatedGas + " units");
        console.log({
          from: loadedConfig.CONTRACT_ADDRESS,
          value: totalCostWei,
        });

        blockchain.smartContract.methods
          .mint(buyQuantity)
          .send({
            to: loadedConfig.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
            gas: estimatedGas,
          })
          .once("error", (err) => {
            console.log(err);
            setDisplayMsg(
              "Sorry, something went wrong please try again later."
            );
            setNFTClaimed(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setDisplayMsg(`WOW, the ${loadedConfig.NFT_NAME} is yours!`);
            setNFTClaimed(false);
            setLoading(false);
            dispatch(fetchData(blockchain.account));
          });
      });
  };

  const loadingAnimation = useSpring({
    from: {
      opacity: 0,
      scale: 0.5,
    },
    to: [
      {
        opacity: 1,
        scale: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--white)",
      transform: "translateY(-100px)",
    },
    to: [
      {
        textAlign: "center",
        color: "var(--white)",
        transform: "translateY(15px)",
      },
    ],
    config: { mass: 3, tension: 500, friction: 25 },
  });

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        theme="dark"
        toastStyle={{
          backgroundColor: "var(--dark)",
          border: "2px solid var(--accent)",
          fontSize: 18,
        }}
        rtl={false}
        draggable
        pauseOnHover
      />
      {loadedConfig !== null && (
        <div
          className="centered new-card"
          style={{ padding: "20px 5px 40px 5px" }}
        >
          <button
            style={{
              position: "absolute",
              left: 15,
              top: 15,
              border: "none",
              outline: "none",
              background: "transparent",
              fontSize: 42,
              color: "var(--tertiary)",
            }}
            onClick={() => (window.location.href = "/")}
          >
            <i class="bi bi-arrow-left-circle-fill"></i>
          </button>
          <animated.div style={loadingAnimation} className="mint-container">
            <div className="centered">
              <h1>
                {data.totalSupply} / {loadedConfig.MAX_SUPPLY}
              </h1>
              <a href={loadedConfig.SCAN_LINK} target="_blank" rel="noreferrer">
                <motion.h5 whileHover={{ fontSize: "22px" }}>
                  {loadedConfig.CONTRACT_ADDRESS.substring(0, 15)}...
                </motion.h5>
              </a>
              {data.totalSupply >= loadedConfig.MAX_SUPPLY ? (
                <div>
                  <h1>The sale has ended.</h1>
                  <h3>You can still find {loadedConfig.NFT_NAME} on</h3>
                  <a
                    target={"_blank"}
                    href={loadedConfig.MARKETPLACE_LINK}
                    rel="noreferrer"
                  >
                    <motion.h3 whileHover={{ fontSize: "32px" }}>
                      {loadedConfig.MARKETPLACE}
                    </motion.h3>
                  </a>
                </div>
              ) : (
                <div>
                  <h2>
                    1 {loadedConfig.SYMBOL} costs {loadedConfig.DISPLAY_COST}{" "}
                    {loadedConfig.NETWORK.SYMBOL}
                  </h2>
                  <h5>Excluding gas fees</h5>

                  {isConnected ? (
                    <div className="row">
                      <h3>{displayMsg}</h3>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          disabled={claimingNFT ? true : false}
                          onClick={handleBuyQuantityDecrement}
                          className="mint-btn-2"
                          style={{
                            width: 40,
                            height: 40,

                            marginBottom: 35,
                            marginRight: 20,
                          }}
                        >
                          <span
                            style={{
                              fontSize: 30,
                            }}
                          >
                            -
                          </span>
                        </button>
                        <span className="mint-text">{buyQuantity}</span>
                        <button
                          disabled={claimingNFT ? true : false}
                          onClick={handleBuyQuantityIncrement}
                          className="mint-btn-2"
                          style={{
                            width: 40,
                            height: 40,
                            marginBottom: 35,
                            marginLeft: 20,
                          }}
                        >
                          <span
                            style={{
                              fontSize: 30,
                            }}
                          >
                            +
                          </span>
                        </button>
                      </div>
                      <center>
                        <button
                          disabled={claimingNFT ? true : false}
                          onClick={handleBuyNFT}
                          className="main-btn"
                          style={{ width: claimingNFT ? "220px" : "150px" }}
                        >
                          <span>
                            {claimingNFT ? "Pending Confirmation" : "BUY"}
                          </span>
                        </button>
                      </center>
                    </div>
                  ) : (
                    <div>
                      <center>
                        <h4>
                          Connect to the {loadedConfig.NETWORK.NAME} network
                        </h4>
                        <br />
                        <button
                          onClick={handleConnect}
                          disabled={isLoading}
                          className="main-btn"
                          style={{ width: "220px" }}
                        >
                          {connectBtnText}
                        </button>
                      </center>
                    </div>
                  )}
                </div>
              )}
            </div>
          </animated.div>
        </div>
      )}
    </div>
  );
};

export default MintConnect;
