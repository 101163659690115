import Footer from "./Components/Footer";
import Navigation from "./Components/Navigation";
import Home from "./sections/Home";
import Solutions from "./sections/Solutions";
import WhatWeDo from "./sections/WhatWeDo";
import Mission from "./sections/Mission";
import FAQ from "./sections/FAQ";
import HowWeHelpArtists from "./sections/HowWeHelpArtists";
import Promotional from "./sections/Promotional";
import AppDevStructure from "./sections/AppDevStructure";
import WhyWeDo from "./sections/WhyWeDo";
import Utility from "./sections/Utility";
import Roadmap from "./sections/Roadmap";
import GoToTop from "./Components/GoToTop";
import useWindowDimensions from "./hooks/useWindowDimensions";
import EmailForm from "./Components/EmailForm";

const App = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      <Navigation />
      <Home />
      <Mission />
      <WhatWeDo />
      <Promotional />
      <HowWeHelpArtists />
      <AppDevStructure />
      <Solutions />
      <Utility />
      <WhyWeDo />
      <Roadmap />
      <EmailForm />
      {/* <AboutFounder /> */}
      <FAQ />
      {width > 992 && <GoToTop />}
      <Footer />
    </div>
  );
};

export default App;
