import { animated, useSpring } from "react-spring";
import FAQCard from "../Components/FAQCard";

const FAQ = () => {
  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      marginBottom: 40,
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        marginBottom: 40,
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  return (
    <div className="container" id="faq" style={{ scrollMarginTop: 140 }}>
      <div style={{ marginTop: 100, marginBottom: 140 }}>
        <center>
          <animated.h1 style={titleAnimation} className="heading-h1">
            <span className="main-heading">Frequently Asked Questions</span>
          </animated.h1>

          <div className="row" style={{ marginTop: 80 }}>
            <div className="FAQCard">
              <FAQCard
                title="What is the supply and Mint Price?"
                text="There will be a total of 10,000 Coda NFTs available for ownership. The Mint price will be 0.2ETH"
              />
              <FAQCard
                title="What is the Launch Date?"
                text="Coda Genesis Official NFT Drop date is TBD. Please be careful of scams, double check our website address before you purchase. Only buy from www.codastreaming.com Launch details will be posted only on our website, Discord, Twitter and Instagram."
              />
              <FAQCard
                title="Are there different rarities?"
                text="There are no different rarities for the Coda Genesis Drop as this is a fundraising mechanism
                and we want to provide the same utility to all of our first users"
              />
              <FAQCard
                title="Is there a Whitelist?"
                text=" yes there will be a 500 person whitelist you can find out more about this from our discord, full
                launch date is TBD."
              />
              <FAQCard
                title="What utility/financial benefits does buying this NFT Provide?"
                text="<ul style='text-align: left;'>
                <li style='margin-bottom: 10px;'>Exclusive invite to be first to the platform.</li>
                <li style='margin-bottom: 10px;'>Highest referral/revenue sharing percentage available from songs streamed through
                your playlist.</li>
                <li style='margin-bottom: 10px;'>Large portion of our ERC-20 Token.
                </li></li>
                <li style='margin-bottom: 10px;'>Substantial amount of bonus engagement points used to unlock things within the
                platform.</li>
                <li style='margin-bottom: 10px;'>Free access to 100 artists exclusive groups of your choice.</li>
                <li style='margin-bottom: 10px;'>A special place in our discord to communicate with other holders, and have real input on
                how the site is built and features you may want added as well giveaways we will run or
                exclusive sales/ rewards for being our first users.</li>
                <li style='margin-bottom: 10px;'>Allows access to future airdrops that provide further utility once the platform goes live.</li>
                <li style='margin-bottom: 10px;'>Profile Verification that proves you were among our first 10,0000 users.</li>
                <li style='margin-bottom: 10px;'>3 Keys to ANY artist's exclusive group despite criteria to get in.
                </li>
                <li style='margin-bottom: 10px;'>VIP access to IRL events for music festivals and concerts.
                </li>
                </ul>"
              />
              <FAQCard
                title="What are NFTs?"
                text="NFT stands for “non-fungible token” which are unique digital items that are stored and encrypted on the blockchain that people can buy, own, and trade. NFTs can simply be digital art but can have various benefits. They can be treated like membership cards where holders get exclusive access to things like websites, events, merchandise, and more."
              />
              <FAQCard
                title="How do I buy Coda NFTs?"
                text="<ol style='text-align: left;'>
                <li style='margin-bottom: 10px;'>Sign up for Metamask or Coinbase Wallet and download the extension on your internet browser.</li>
                <li style='margin-bottom: 10px;'>Make sure you have enough Ethereum in your wallet to cover the total cost including gas fees.</li>
                <li style='margin-bottom: 10px;'>On mint day, there will be a <b>Connect</b> button at the top of our website to connect to your wallet.</li>
                <li style='margin-bottom: 10px;'>Click on the mint button and you will be prompted to sign for your transaction. There will be a fee associated with every transaction related to gas prices.</li>
                <li> Once you have purchased your Coda NFT, it will appear in your wallet and on Opensea.</li></ol>"
              />
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};

export default FAQ;
