import { useSpring, animated } from "react-spring";
import { motion } from "framer-motion";

import asset_1 from "../assets/asset_1.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Home = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  return (
    <div
      id="home"
      style={{
        position: "relative",
        height: "100vh",
        marginTop: width < 992 && 160,
      }}
    >
      <div className="container-fluid centered">
        <animated.div
          className="row justify-content-center"
          style={titleAnimation}
        >
          <div className="col-md-auto" style={{ margin: "auto 100px" }}>
            <h1
              className="home-text"
              style={{ textAlign: width > 992 ? "left" : "center" }}
            >
              Welcome to
              <br />
              <span
                style={{
                  fontSize: 72,
                  fontWeight: "bold",
                  textShadow: "1px 1px 75px var(--primary)",
                }}
              >
                Coda
              </span>
              <br />
              <span
                style={{
                  fontSize: 16,
                  letterSpacing: 5,
                  display: "block",
                  lineHeight: "40px",
                  textShadow: "1px 1px 75px var(--primary)",
                }}
              >
                Where artists
                <br style={{ padding: 0 }} />
                and listeners promote.
              </span>
            </h1>
            <br />

            <a style={{ all: "unset" }} href="">
              <button className="main-btn">
                <span>Mint Coming Soon!</span>
                <i className="fas fa-angle-right"></i>
              </button>
            </a>
            {width < 992 && <br />}
            {width < 992 && <br />}
            <a
              href="#subscribe"
              style={{ all: "unset", marginLeft: width > 992 && 20 }}
            >
              <motion.button
                className="main-btn"
                style={{
                  paddingLeft: 15,
                  background: "transparent",
                  color: "var(--tertiary)",
                  border: "2px solid var(--tertiary)",
                }}
              >
                Signup for Release
                <i
                  className="fas fa-envelope"
                  style={{ color: "var(--tertiary)" }}
                ></i>
              </motion.button>
            </a>
          </div>
          <div className={`col-md-auto ${width < 992 && "mt-4"}`}>
            <img
              src={asset_1}
              width={width > 992 ? "95%" : "70%"}
              style={{ userSelect: "none", pointerEvents: "none" }}
            />
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default Home;
