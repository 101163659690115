import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import "../styles/index.scss";

const FAQCard = (props) => {
  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.5,
      scale: 0.9,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="faq-item"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
    >
      <motion.div whileHover={{ scale: 1.025 }}>
        <input type="checkbox" id={props.text} />
        <label className="faq-item-label" htmlFor={props.text}>
          <span style={{ width: "100%" }}>{props.title}</span>
        </label>
        <div className="faq-item-content">
          <p dangerouslySetInnerHTML={{ __html: props.text }} />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default FAQCard;
