const SolutionCard = ({ title, desc }) => {
  return (
    <div className="solution-card">
      <h2 className="solution-card-title">{title}</h2>
      <p className="solution-card-description">{desc}</p>
    </div>
  );
};

export default SolutionCard;
