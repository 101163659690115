import { animated, useSpring } from "react-spring";
import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import assetOne from "../assets/asset_2.png";
import assetTwo from "../assets/asset_3.png";
import assetThree from "../assets/asset_4.png";

import RoadmapCard from "../Components/RoadmapCard";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Roadmap = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      marginBottom: 40,
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        marginBottom: 40,
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.1,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      id="roadmap"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
      style={{
        marginBottom: 120,
        paddingTop: width > 900 && 140,
        scrollMarginTop: 0,
      }}
    >
      <div style={{ marginBottom: 80 }}>
        <center>
          <animated.h1 style={titleAnimation}>
            <div>
              <span className="main-heading">The Road to Success</span>
            </div>
          </animated.h1>
        </center>
      </div>

      <ul className="roadmap">
        <RoadmapCard
          dir={"r"}
          title={"Develop MVP of application"}
          img={assetOne}
          desc={
            "Our team has constructed an MVP (minimum viable product) and is continuing to improve and test the platform"
          }
        />
        <RoadmapCard
          dir={"l"}
          title={"Raise vc Funding"}
          desc={
            "Our team has already raised $250,000 of VC funding from JGJ Ventures to continue to build out the MVP and to pour into marketing, brand awareness and user acquisition."
          }
        />
        <RoadmapCard
          dir={"r"}
          title={"Coda Marketing"}
          desc={
            "$140,000 has been allocated to marketing and brand awareness to ensure a massively successful first drop at full scale."
          }
        />
        <RoadmapCard
          dir={"l"}
          title={"Artists Partnerships"}
          img={assetTwo}
          desc={
            "We are currently in discussions and implementing deals with big name artists to ensure we cultivate a strong community and starting user base that will ensure the platform's health and growth."
          }
        />
        <RoadmapCard
          dir={"r"}
          title={"NFT Fundraising Round"}
          desc={
            "Coda is aiming to raise $5,000,000 operating capital for the company from the Coda Genesis NFT drop. We are well backed and have extensive resources being contributed to marketing to support overall reach/awareness of our platform."
       
          }
        />
        <RoadmapCard
          dir={"l"}
          title={"Use of Funds"}
          desc={
            "The $5,000,000 will be split between beta creation and further development of our team as well as the platform. Marketing and brand awareness to target crypto native musicians and provide more buzz around the platform will also be included in this spend. Some portion of that capital will be locked into an ERC 20 token that the site will run off to ensure instant payments to artists and listeners."
          }
        />
        <RoadmapCard
          dir={"r"}
          title={"Private Alpha development"}
          desc={
            "This will take 2-3 months and select artists will then be brought on with an invite only basis in order to iron out the bugs, get user feedback on the alpha, and get a general collection of NFTs and music onto the platform. This will be done while simultaneously testing the NFT marketplace, royalty sharing, smart contracts, etc."
          }
        />
        <RoadmapCard
          dir={"l"}
          title={"Building the beta"}
          desc={
            "Going from an alpha to a beta version of the platform will take approximately 1-2 more months. Commitments have been made from JGJ Ventures for additional rounds of funding if needed."
          }
        />
        <RoadmapCard
          dir={"r"}
          img={assetThree}
          title={"Inviting Select Users"}
          desc={
            "Coda Genesis NFT holders will then be the first ones invited to the platform after reviewing small group user feedback and bug fixes."
            
          }
        />
        <RoadmapCard
          dir={"l"}
          title={"Before Product Launch"}
          desc={
            "Once the beta is built we will implement deals made with big name artists to ensure we have a good base of music and large audience when it comes time to launch."
          }
        />
        <RoadmapCard
          dir={"r"}
          title={"Product Launch"}
          desc={
            "We will provide incentives to listeners and artists to share the platform in the form of engagement points, giveaways, special access IRL (in real life) events at prominent music festivals, discounts and more. We will also be allocating serious capital to attracting big names, expand brand awareness, and provide user growth incentives."
          }
        />
      </ul>
    </motion.div>
  );
};

export default Roadmap;
