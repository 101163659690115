import { animated, useSpring } from "react-spring";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import useWindowDimensions from "../hooks/useWindowDimensions";

const Mission = () => {
  const { width } = useWindowDimensions();

  const titleAnimation = useSpring({
    from: {
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.3,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
      id="about"
      style={{
        position: "relative",
        marginTop: width < 992 && 40,
        marginBottom: 80,
      }}
      className="container"
    >
      <center>
        <animated.h1 style={titleAnimation}>
          <span className="main-heading">Mission</span>
        </animated.h1>
        <br />
        <p className="main-p" style={{ fontSize: 24 }}>
          Coda is the first music streaming platform and NFT marketplace that provides the tools
          to help artists grow a strong community by letting listeners invest in and own music
          royalties.
        </p>
      </center>
    </motion.div>
  );
};

export default Mission;
