import { useAnimation, motion } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "react-spring";

import SolutionCard from "../Components/SolutionCard";

const Solutions = () => {
  const titleAnimation = useSpring({
    from: {
      textAlign: "center",
      color: "var(--light)",
      marginBottom: 40,
      transform: "translateX(-100px)",
      opacity: 0,
    },
    to: [
      {
        textAlign: "center",
        color: "var(--light)",
        marginBottom: 40,
        transform: "translateX(0px)",
        opacity: 1,
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  const viewAnim = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.4 },
    },
    hidden: {
      opacity: 0.3,
      scale: 1.1,
      transition: { duration: 0.4 },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      id="solutions"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={viewAnim}
      style={{
        scrollMarginTop: 120,
        height: "100%",
      }}
    >
      <div style={{ marginBottom: 80 }}>
        <center>
          <animated.h1 style={titleAnimation}>
            <div>
              <span className="main-heading">
                Solutions to Music Industry Problems
              </span>
            </div>
          </animated.h1>
        </center>
      </div>
      <div className="solutions-container">
        <div className="solution-cards">
          <SolutionCard
            title={"Slow payout times for artists"}
            desc={
              "Big streaming platforms can take up to 12 months to pay artists once a song is          streamed, with Coda they are paid instantly."
            }
          />
          <SolutionCard
            title={"Small music ownership"}
            desc={
              "Musicians and artists rarely own their own music rights. Coda solves this issue              for the artist by putting them in control of their own distribution. At Coda, they control their own music rights and can decide how to distribute the revenues those rights generate through NFTs."
            }
          />
          <SolutionCard
            title={"Small stream payout..."}
            desc={
              "Instead of all subscription income going into a pool and paying out the biggest artists first, like other streaming platforms, we use a “user centric payout system”. This means that instead of going from you to a pool of money, it goes directly to your favorite artists. We are also providing direct donations to artists for people who really want to support their favorite musicians. We are opening up lots of new streams of revenue and better means of fundraising all together"
            }
          />
          <SolutionCard
            title={"No feedback loops..."}
            desc={
              "We are connecting the artists and their listeners by having social groups that the artists operate within the platform. This helps cultivate a personal connection with listeners and results in a super supportive community of die-hard fans that can now act as promoters."
            }
          />
          <SolutionCard
            title={"No incentive for listeners to help artists grow..."}
            desc={
              "We are enabling our listeners to invest in the artists they love. Along with lots of other options, users can buy collectables, exclusive listening rights or a percentage of royalties in an artist’s song, etc. By Coda providing a method for these incentives, previously passive listeners now turn into the artist’s army of promoters, or as we like to call them “AMPs” (artist music promoters). Listeners now have reason to go out of their way to find new upcoming artists as well as share their favorite musical creators. Providing these incentives turns eyes to smaller artists giving them a massive boost as listeners hope to make a return from their growth."
            }
          />
          <SolutionCard
            title={"Little control for artists..."}
            desc={
              "In existing platforms artists have zero information about who their listeners are and which ones are their biggest contributors. Since we are able to fix this hole in the feedback loop, we are incentivizing listeners to be SUPER FANS. Artists can provide exclusive rights and opportunities to whatever demographic of their listeners they wish, putting them back in control of their own music."
            }
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Solutions;
