import "bootstrap-icons/font/bootstrap-icons.css";

const Footer = () => {
  return (
    <footer className="w-100 py-4 flex-shrink-0 footerbar">
      <div className="container py-4">
        <div className="row gy-4 gx-5">
          <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-4"></div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
              <center>
                <div className="social-menu">
                  <ul>
                    <li>
                      <a
                        href="https://www.instagram.com/codastreaming"
                        target="blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/Codastreaming"
                        target="blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://discord.gg/TXEvZ5GdDf"
                        target="blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-discord" />
                      </a>
                    </li>
                  </ul>
                </div>
              </center>
              <h5
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: 15,
                }}
              >
                © All right Reserved:{" "}
                <a
                  href="?"
                  target="_self"
                  style={{
                    textDecoration: "none",
                    color: "var(--primary-light)",
                  }}
                >
                  <div>&nbsp;Coda</div>
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
